<footer>
    <div class="container">
        <!-- <div class="subscribe-area">
            <div class="section-title">
                <h2>Subscribe Newsletter</h2>
            </div>

            <div class="subscribe-shape">
                <img src="./assets/img/shape/1.png" alt="Shape">
                <img src="./assets/img/shape/1.png" alt="Shape">
                <img src="./assets/img/shape/1.png" alt="Shape">
                <img src="./assets/img/shape/1.png" alt="Shape">
                <img src="./assets/img/shape/2.png" alt="Shape">
            </div>

            <div class="subscribe-item">
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL" required autocomplete="off">
                    <button class="btn subscribe-btn" type="submit">Subscribe</button>
                </form>
            </div>
        </div> -->

        <div class="row mt-2 pt-3">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/" style="width: 120px;"><img src="./assets/img/logo.svg" alt="Logo"></a>
                        <p>Adeegga SAHAN waa adeeg isku xiraya dadka shaqodoonka ah iyo xarumaha laga doonayo shaqaale aqoon, tayo iyo xirfad leh, waxuuna si sahlan oo farriin “SMS” ah ugu gudbinayaa macaamiisha fursadaha shaqo ee ka bannaan dalka</p>
                        <!-- <ul>
                            <li><a href="#" target="_blank"><i class="icofont-youtube-play"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-category">
                        <h3>Category</h3>

                        <ul>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Development</a></li>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Business</a></li>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Tech & IT</a></li>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Finance</a></li>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Networking</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-category">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Home</a></li>
                            <li><a routerLink="/job-list"><i class="icofont-simple-right"></i> Jobs</a></li>
                            <li><a routerLink="/job-list" [queryParams]="{type: 'Tender'}"><i class="icofont-simple-right"></i> Tenders</a></li>
                            <li><a routerLink="/job-list" [queryParams]="{type: 'Course'}"><i class="icofont-simple-right"></i> Courses</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>Find Us</h3>

                        <ul>
                            <li><i class="icofont-location-pin"></i> Mogadishu, Somalia</li>
                            <!-- <li><i class="icofont-ui-call"></i> <a href="tel:+252906790024">+252906790024</a></li> -->
                            <!-- <li><i class="icofont-ui-email"></i> <a href="mailto:info@sahanjobs.com">info@sahanjobs.com</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <p>© Sahan <a href="#" target="_blank">Jobs</a></p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item copyright-right">
                        <!-- <ul>
                            <li><a routerLink="/terms-and-conditions" target="_blank">Terms & Conditions</a></li>
                            <li><span>-</span></li>
                            <li><a routerLink="/privacy-policy" target="_blank">Privacy Policy</a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>